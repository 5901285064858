<template>
    <div class="flex justify-between">
        <StatusButton v-for="(count, status) in currentStatuses" :key="status" :active="selectedInvoiceType === status" @click="statusClicked(status)">
            <template #label>{{ capitalizeFirstLetter(status) }}</template>
            <template #count>{{ count }}</template>
        </StatusButton>
    </div>
</template>
<script>
import queryString from "query-string";
import Api from "./factoring.api.js";

export default {
    data() {
        return {
            currentStatuses: [],
            selectedInvoiceType: "paidCount",
            invoiceTypes: {
                all: ["Alla", "Betalade", "Delbetalda", "Förfallna", "Överbetalda", "Obetalda", "Nekade"],
            },
        };
    },

    components: {
        StatusButton: () => import("@/pages/invoices/components/StatusButton.vue"),
    },

    created() {
        if (!this.isGlobalClientSelected) this.getAll();
    },

    methods: {
        async getAll() {
            const { search, clientId } = queryString.parse(window.location.search, { sort: false });
            this.currentStatuses = await Api.getInvoiceCounts(search, clientId);
            const query = queryString.parse(window.location.search, { sort: false });
            this.selectedInvoiceType = query.status ? query.status : "paidCount";
        },

        statusClicked(status) {
            this.selectedInvoiceType = status;
            const query = queryString.parse(window.location.search, { sort: false });
            query.pageNumber = 1;
            query.status = status;
            this.$router.replace({ query }).catch(() => {});
            this.$emit("searchParametersChanged");
        },

        capitalizeFirstLetter(string) {
            const translation = {
                paidCount: "Betalade",
                partiallyPaidCount: "Delbetalda",
                overpaidCount: "Överbetalda",
                overdueCount: "Förfallna",
                unpaidCount: "Obetalda",
                deniedCount: "Nekade",
                totalInvoices: "Alla",
            };
            return translation[string];
        },
    },

    computed: {
        isGlobalClientSelected() {
            return this.$store.getters["topBar/isClientSelected"];
        },
    },
};
</script>
